import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Meta from './atoms/Meta';
import VacancyList from './molecules/VacancyList';
import theme from '../constants/theme';
import Footer from './organisms/Footer';
import { Section } from './atoms/Section';
import withDefaultTransition from '../hocs/withDefaultTransition';
import CTA from './molecules/CTA';
import {
    CTAFragment,
    VacancyListItemFragment,
    VacaturesOverviewFragment,
    VacaturesPageQuery,
} from '../entities/operationResults';

interface Props {
    data: VacaturesPageQuery;
}

export const query = graphql`
    query VacaturesPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...VacaturesOverviewFragment
            }
            entries(site: $site, type: Vacancy) {
                ...VacancyListItemFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment VacaturesOverviewFragment on Craft_Vacatures {
        id
        title
        vacancyList {
            ...VacancyListFragment
        }
        callToAction {
            ...CTAFragment
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

const Vacatures: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as VacaturesOverviewFragment;
    const title = entry.title && entry.title;
    const vacancyList = entry.vacancyList && entry.vacancyList[0];
    const vacancies = data.craft && (data.craft.entries as VacancyListItemFragment[]);
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const footer = entry.directContact && entry.directContact[0];
    const meta = entry.seo && entry.seo[0];

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : null}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section background={theme.colors.shark} fontColor={theme.colors.white} id="vacatures" elevate>
                {vacancyList && vacancies && <VacancyList vacancies={vacancies} vacancyList={vacancyList} />}
                {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} withMargin align="right" />}
            </Section>
            <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} hasFlatTop />}</Section>
        </>
    );
};

export default withDefaultTransition(Vacatures);
